import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import PortfolioType from '../components/PortfolioType'

class LBCardPortfolio extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      articleClass : "lbcard-pf card-home col-md-5"
    }
  }

  componentDidMount(){
    this.manageArticleClass();
  }

  manageArticleClass() {
    // case: home - total 3 articles - 1st is main for tablet 
    if (this.props.page === "home" && this.props.articleNumber === 1) {
      this.setState({
        articleClass: "lbcard-pf card-home main-article-md col-md-11"
      })
    }
  }

  render() {

    const post = this.props.post
    //console.log(post)
    return (
        <article className={this.state.articleClass} style={{border: `5px solid ${post.lateralStripesColor}`}}>
          <div className="lbcard-pf_image-wrapper">
            <div className="lbcard-pf_info-wrapper">
              <div className="lbcard-pf_logo">
                <Img className="lbcard-pf_image-logo"
                  fixed={post.logo.fixed}
                  alt=""
                />
              </div>
              <div className="lbcard-pf_title-wrapper">
                <h2 className="lbcard-pf_title">{post.title}</h2>
              </div>
              <PortfolioType articleType={post.articleType} projectType={post.projectType}></PortfolioType>
            </div>
            <Link key={post.slug} to={`/portfolio/${post.slug}/`} className="lbcard-pf_image-link">
              <Img className="lbcard-pf_image"
                fluid={post.heroImage.fluid}
                alt=""
              />
            </Link>
          </div>
          <div key={"wrapper"+post.slug} className="lbcard-pf_content-wrapper" style={{borderTop: `5px solid ${post.lateralStripesColor}`}}>
            <Link to={`/portfolio/${post.slug}/`} key={post.slug} className="lb_card-pf-read-more">
              show {post.articleType}
            </Link>
          </div>
        </article>
    )
  }
}

export default LBCardPortfolio
