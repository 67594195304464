import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import LBNavigation from '../components/LBNavigation'
import SEO from '../components/SEO'
import { startCase } from 'lodash'
import LBCardPortfolio from '../components/LBCardPortfolio'

const Portfolios = ({ data, pageContext }) => {
  const portfolios = data.allContentfulPortfolio.edges
  
  const { humanPageNumber, basePath } = pageContext
  let ogImage
  


  console.log(basePath);

  try {
    ogImage = portfolios[0].node.heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }
  
  return (
    <div className="bg-index-portfolio">
      <LBNavigation isHome={false}/>
      <Layout>
        <SEO title={startCase(basePath)} image={ogImage} />
        <div className="title-eyebrow eyebrow-portfolio"><Link to="/">Home </Link> / Portfolio</div>
        <h1 className="title portfolio">Portfolio</h1>
        <div className="portfolio-wrapper row no-gutters justify-content-center">
          {portfolios.map(({ node: pf}, index) => (
            <LBCardPortfolio key={pf.id} post={pf} page="portfolio" articleNumber={index+1} basePath={basePath} />
          ))}
        </div>
        <div className="tutti-gli-articoli-cta">
        </div>
      </Layout>
      <div className="portfolio-bg"></div>
    </div>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPortfolio(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          articleType
          projectType
          lateralStripesColor
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            ogimg: resize(width: 1800) {
              src
            }
          }
          logo {
            title
            fixed(width: 62, quality: 80) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default Portfolios
