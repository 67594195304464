import React from 'react'

function ProjectType ({type}) {
  return(
    <>
      { type === "webapp" ? <div className="article-type-icon icon-webapp"></div> : ""}
      { type === "website" ? <div className="article-type-icon icon-website"></div> : ""}
      { type === "mobile app" ? <div className="article-type-icon icon-mobile"></div> : ""}
    </>
  )
}

class PortfolioType extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <>
        <section className="portfolio-type">
          <div className="portfolio-type-wrapper">
            <div className="portfolio-type_article-type">{this.props.articleType}</div>
            <div className="portfolio-type_dot">&#183;</div>
            <div className="portfolio-type_project-type">{this.props.projectType}</div>
          </div>
          <div className="portfolio-type_icon-wrapper">
            <ProjectType type={this.props.projectType}></ProjectType>
          </div>
        </section>
      </>
    )
  }
}

export default PortfolioType
